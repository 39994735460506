import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { Helmet } from 'react-helmet'
import { PersistGate } from 'redux-persist/integration/react'
import { initGA } from './analytics';

import { store, persistor } from './store/store'

import AppWrapper from './AppWrapper'

import Cart from './components/Cart'
// import exportTranslation from './utils/exportTranslation'

import LoadingScreen from './components/pages/LoadingScreen'

import MainMenu from './components/MainMenu'
import MobileMenu from './components/MobileMenu'
import SecondaryMenu from './components/SecondaryMenu'

import PopupLogin from './components/PopupLogin'
import PopupSignup from './components/PopupSignup'
import PopupResetPassword from './components/PopupResetPassword'
import PopupOverlay from './components/PopupOverlay'
import Message from './components/Message'
import LanguageChooser from './components/LanguageChooser'
import Search from './components/Search'

import PageLoad from './components/PageLoad'
import NotFoundPage from './components/pages/NotFoundPage'
import LanguageRouter from './LanguageRouter'
import LanguageRedirect from './LanguageRedirect'
// import Settings from './Settings'

import RouteTracker from './RouteTracker';
import './css/_index.scss'
import { MarkerDataProvider } from "./context/MarkerDataContext";
// import 'semantic-ui-css/semantic.min.css'

// console.log(`${Settings.server}${Settings.baseName}`)
// console.log(process.env.PUBLIC_URL)

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
})

const App = () => {
  useEffect(() => {
    initGA();
  }, []);

  return (
    <Provider store={store}>
      {/* exportTranslation('it') */}
      <PageLoad>
        <PersistGate loading={null} persistor={persistor}>
          <MarkerDataProvider>
            <RouteTracker/>
            <AppWrapper>
              <Helmet>
                <meta charSet="utf-8"/>
                {<title>Laboratoire homéopathique Schmidt-Nagel</title>}
              </Helmet>
              <div className="app_content">
                <Switch>
                  <Route path="/" exact component={() => <LanguageRedirect/>}/>
                  <Route
                    path="/fra/page.htm"
                    exact
                    component={() => (
                      <Redirect
                        to={{
                          pathname: '/fr/',
                          state: {status: 301},
                        }}
                      />
                    )}
                  />
                  <Route
                    path="/homeopathie/"
                    exact
                    component={() => (
                      <Redirect
                        to={{
                          pathname: '/fr/medecine/homeopathie',
                          state: {status: 301},
                        }}
                      />
                    )}
                  />
                  <Route
                    path="/fra/intro.html"
                    exact
                    component={() => (
                      <Redirect
                        to={{
                          pathname: '/fr/',
                          state: {status: 301},
                        }}
                      />
                    )}
                  />
                  <Route
                    path="/en/laboratory/doctor-pierre-schmidt-homeopath/"
                    exact
                    component={() => (
                      <Redirect
                        to={{
                          pathname: '/en/company/story',
                          state: {status: 301},
                        }}
                      />
                    )}
                  />
                  <Route
                    path="/en/present/ps.htm"
                    exact
                    component={() => (
                      <Redirect
                        to={{
                          pathname: '/en/company/story',
                          state: {status: 301},
                        }}
                      />
                    )}
                  />
                  <Route
                    path="/fr/"
                    component={() => <LanguageRouter language="fr"/>}
                  />
                  <Route
                    path="/en/"
                    component={() => <LanguageRouter language="en"/>}
                  />
                  <Route
                    path="/de/"
                    component={() => <LanguageRouter language="de"/>}
                  />
                  <Route
                    path="/it/"
                    component={() => <LanguageRouter language="it"/>}
                  />
                  <Route component={() => <NotFoundPage language=""/>}/>
                </Switch>

                <SecondaryMenu/>
                <MainMenu/>
                <Cart/>
              </div>
              <PopupOverlay/>
              <PopupLogin/>
              <PopupSignup/>
              <PopupResetPassword/>
              <LanguageChooser/>
              <Message/>
              <Search/>
              <MobileMenu/>
              <LoadingScreen/>
            </AppWrapper>
          </MarkerDataProvider>
        </PersistGate>
      </PageLoad>
    </Provider>
  )
}

export default App
