import Airtable from 'airtable';

const base = new Airtable({apiKey: 'patSgxFo60yV5QB7W.3e71de80dccd96702c81444144e7a91124a6bb7eb1721899d941a7bd92857a1e'}).base('appEsmIanw8NdFymo');

export const getLocations = async () => {
  const records = await base('Map').select().all();

  return records
    .map(record => ({
      id: record.id,
      name: record.fields.Nom,
      category: record.fields.Catégorie,
      address: record.fields.Adresse,
      latitude: parseFloat(record.fields.Latitude),
      longitude: parseFloat(record.fields.Longitude),
      function: record.fields.Fonction,
      website: record.fields['Site internet']
    }))
};